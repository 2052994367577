<template>
  <viewcard--c
    :title="$route.params.id > 0 ? 'Atualizar Formato' : 'Cadastrar Formato'"
    :btsave="$route.params.id > 0 ? btedit : btcreate"
    :btdelete="null"
    :btback="{}"
    :busy="loading"
    @clicked-save="validationForm"
    @clicked-delete="
      {
      }
    "
  >
    <hr class="p-0 m-0 mb-1" />
    <validation-observer ref="formatRules">
      <b-form @submit.prevent>
        <b-row>
          <b-col md="6">
            <b-form-group label="Nome *">
              <validation-provider
                #default="{ errors }"
                name="Nome do Formato"
                rules="required"
              >
                <b-form-input
                  v-model="record.name"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Nome do formato"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group label="Ativo">
              <b-form-checkbox
                class="custom-control-success mt-1"
                name="check-button"
                switch
                v-model="record.active"
              >
                <span class="switch-icon-left">
                  <feather-icon icon="CheckIcon" />
                </span>
                <span class="switch-icon-right">
                  <feather-icon icon="XIcon" />
                </span>
              </b-form-checkbox>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </viewcard--c>
</template>
<script>
import _formatTypeService from "@/services/audiovisual/format-type-service";
import { ValidationProvider, ValidationObserver, localize } from "vee-validate";
import pt_br from "vee-validate/dist/locale/pt_BR.json";
import { required } from "@validations";
import { BFormFile } from "bootstrap-vue";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BFormFile,
  },
  data() {
    return {
      btedit: { permission: `permission.audiovisual.demands.typeformats.edit` },
      btcreate: {
        permission: `permission.audiovisual.demands.typeformats.create`,
      },
      btdelete: {
        permission: `permission.audiovisual.demands.typeformats.delete`,
      },
      loading: false,
      record: {
        id: 0,
        name: "",
        active: true,
      },
    };
  },
  created() {
    localize("pt_BR", pt_br);
    this.getRecord();
  },
  methods: {
    validationForm() {
      this.$refs.formatRules.validate().then((success) => {
        if (success) {
          this.save();
        }
      });
    },
    getRecord() {
      if (this.$route.params.id > 0) {
        this.loading = true;
        _formatTypeService
          .find(this.$route.params.id)
          .then((res) => {
            this.record = res.content;
          })
          .catch((error) => this.$utils.toastError("Notificação", error))
          .finally(() => (this.loading = false));
      }
    },
    save() {
      const payload = { data: { ...this.record } };

      //promisse
      const _createOrUpdate =
        this.record.id <= 0
          ? _formatTypeService.create(payload)
          : _formatTypeService.update(payload);

      this.loading = true;
      _createOrUpdate
        .then(() => {
          this.$utils.toast("Notificação", "Registro salvo com sucesso.");
          this.$router.go(-1);
        })
        .catch((error) => this.$utils.toastError("Notificação", error))
        .finally(() => (this.loading = false));
    },
  },
};
</script>